import axios from '../libs/ajaxRequest'

/* 购物车列表 */
const shoppingCardList = () => {
    return axios.request({
        url: 'basket/list',
        method: 'get'
    });
};

/* 删除购物车 */
const delCardList = (data) => {
    return axios.request({
        url: 'basket/del',
        method: 'post',
        data
    })
}

export {
    shoppingCardList,
    delCardList
}