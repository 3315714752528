<template>
    <div id="shoppingCard">
        <div class="navbar">
            <button>购物车</button>
            <button v-if="isEdit" @click="changeEditStatus(false)">完成</button>
            <button v-else @click="changeEditStatus(true)">编辑</button>
        </div>
        <div class="content" v-if="cardList">
            <van-checkbox-group v-model="result" ref="CheckboxGroup"   @change="change">
                
                <div class="card" v-for="(item, index) in cardList" :key="index">
                    <van-checkbox :name="item.id"></van-checkbox>
                    <img :src="item.listImg" @click="jump(item.productId)"/>
                    <div class="text">
                        <h4 @click="jump(item.productId)">{{item.productName}}</h4>
                        <div>
                            <span>{{item.sku}}</span>
                            <van-stepper class="stepper" button-size="20" v-model="item.quantity" @change="changeNum(index)"/>
                        </div>
                        <p>{{`￥${fixedPrice(item.salePrice)} + ${item.integral}良豆`}}</p>
                    </div>
                </div>

            </van-checkbox-group>
        </div>

        <div v-if="isEdit" class="editBar">
                <!-- <van-checkbox v-model="editCheck" @change="changeEdit">全选</van-checkbox> -->
                <button @click="changeEdit" class="selectAll">
                    <div v-if="editChecked" class="van-checkbox__icon van-checkbox__icon--round van-checkbox__icon--checked">
                        <i class="van-icon van-icon-success"></i>
                    </div>
                    <div v-else class="van-checkbox__icon van-checkbox__icon--round">
                        <i class="van-icon van-icon-success"></i>
                    </div>
                    <span>全选</span>
                </button>
                <button class="settlement" @click="delcard">删除</button>
        </div>

        <van-submit-bar v-else :price="price*100" :suffix-label="`${integral}良豆`" class="settlementBox">
            <!-- <van-checkbox v-model="listCheck" @change="changeList">全选</van-checkbox> -->
            <button @click="changeList" class="selectAll">
                <div v-if="listChecked" class="van-checkbox__icon van-checkbox__icon--round van-checkbox__icon--checked">
                    <i class="van-icon van-icon-success"></i>
                </div>
                <div v-else class="van-checkbox__icon van-checkbox__icon--round">
                    <i class="van-icon van-icon-success"></i>
                </div>
                <span>全选</span>
            </button>
            <template #button>
                <button class="settlement" @click="settlement">结算</button>
            </template>
        </van-submit-bar>

        <confirmOrder
        :confirmOrderShow="confirmOrderShow"
        :productDetail="productDetail"
        :popupShow.sync="confirmOrderShow"/>
    </div>
</template>

<script>
import {shoppingCardList, delCardList} from '../../api/shoppingCard'
import confirmOrder from '../component/confirmOrder'
export default {
    data () {
        return {
            result: [],
            isEdit: false, //是否进入编辑状态
            /* listCheck: false, //购物车列表全选
            editCheck: false, //购物车编辑状态下列表全选 */
            cardList: '',
            price: 0,
            integral: 0,
            ids: [], //删除购物车产品
            selectList: '',  //选中产品列表
            productDetail: [],
            confirmOrderShow: false,
            listChecked: false, //购物车列表全选
            editChecked: false, //购物车删除列表全选
        }
    },
    mounted () {
        // console.log();
        this.getCardList();
    },
    components: {confirmOrder},
    computed: {
        fixedPrice () {
            return (price) => {
                return price.toFixed(2);
            }
        }
    },
    methods: {
        /* 切换编辑 */
        changeEditStatus (status) {
            this.isEdit = status;
            this.$refs.CheckboxGroup.toggleAll(false);
            this.listChecked = false;
            this.editChecked = false;
        },

        /* 购物车列表全选 */
        changeList () {
            const {listChecked} = this;
            if (listChecked) { //取消全选
                this.listChecked = false; 
                this.productDetail = [];
            this.$refs.CheckboxGroup.toggleAll(false);
            } else { //全选
                this.listChecked = true;
                this.productDetail = this.cardList;
                this.$refs.CheckboxGroup.toggleAll(true);
            }
            // checked ? this.productDetail = this.cardList : this.productDetail = [];
        },

        /* 购物车编辑状态下全选 */
        changeEdit () {
            const {editChecked} = this;
            if (editChecked) {
            this.editChecked = false;
                this.$refs.CheckboxGroup.toggleAll(false);
            } else {
                this.editChecked = true;
                this.$refs.CheckboxGroup.toggleAll(true);
            }
        },

        /* 购物车选择 */
        change (list) {
            console.log(list);
            const {cardList,isEdit} = this;
            if (isEdit) {
                this.ids = list;
                (list.length > 0 && list.length === cardList.length) ? this.editChecked = true : this.editChecked = false;
            } else {
                (list.length > 0 && list.length === cardList.length) ? this.listChecked = true : this.listChecked = false;
                if (list.length < 1) {
                    this.price = 0;
                    this.integral = 0;
                    this.selectList = '';
                } else {
                    this.selectList = list;
                    this.price = 0;
                    this.integral = 0;
                    let arr = [];
                    for (var i=0; i<list.length; i++) {
                        for (var n=0; n<cardList.length; n++) {
                            if (cardList[n].id == list[i]) {
                                this.price += cardList[n].salePrice*cardList[n].quantity;
                                this.integral += cardList[n].integral*cardList[n].quantity;
                                arr.push(cardList[n]);
                            }
                        }
                    };
                    this.productDetail = arr;
                }
            }
        },

        /* 添加数量 */
        changeNum (index) {
            const {selectList,cardList} = this;
            this.price = 0;

            this.integral = 0;
            for (var i=0; i<selectList.length; i++) {
                for (var n=0; n<cardList.length; n++) {
                    if (cardList[n].id == selectList[i]) {
                        this.price += cardList[n].salePrice*cardList[n].quantity;
                        this.integral += cardList[n].integral*cardList[n].quantity;
                    }
                }
            }
        },

        /* 删除购物车 */
        delcard () {
            const {ids} = this;
            console.log(ids);
            if (ids.length > 0) {
                this.$dialog.confirm({
                    title: '提示',
                    message: '确认删除吗?',
                })
                .then(() => {
                    this.confirmDel();
                })
                .catch(() => {

                });
            } else {
                this.$toast('请选择产品');
            }
        },

        /* 确认删除 */
        async confirmDel () {
            const {ids} = this;
            let res = await delCardList({ids});
            console.log(res);
            const {code, data, msg} = res;
            if (code === 1) {
                this.$toast('删除成功');
                this.ids = [];
                this.editChecked = false;
                this.listChecked = false;
                this.getCardList();
            }
        },


        /* 获取购物车列表 */
        async getCardList () {
            let res = await shoppingCardList();
            const {code, data, msg} = res;
            if (code === 1) {
                this.cardList = data.list.reverse();
                console.log(this.cardList);
            } 
        },

        /* 结算 */
        settlement () {
            const {selectList} = this;
            if (selectList.length < 1) {
                this.$toast('请先选择商品');
                return;
            }
            this.confirmOrderShow = true;
        },

        jump (productId) {
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, code, state, integral} = $route.query;
            $router.push({name: 'productdetails', query:{appKey, credits, timestamp, sign, uid, productId, code, state, integral}});
        }

    }
}
</script>

<style lang="less" src="@/style/shoppingCard/shoppingCard.less">

</style>